import {useEffect, useRef} from "react";
import {useQuery} from '@tanstack/react-query';
import {createConnectTransport, createGrpcWebTransport} from "@connectrpc/connect-web";
import {createPromiseClient} from "@connectrpc/connect";
import {Processor} from "@buf/localize_localize.connectrpc_es/localize/processor_connect";
import {ParseRequest, StringifyRequest} from "@buf/localize_localize.bufbuild_es/localize/processor_pb";
import {fromProtoSegments, toProtoSegments} from "@localizesh/sdk";
import {SegmentType, useSegmentsData} from "../provider/segmentsProvider";


const createMyPromiseClient = (fileExtension) => {
  if(!fileExtension) return {}

  const isRstExtension = fileExtension === "rst"

  const createTransportFunc = isRstExtension ? createGrpcWebTransport : createConnectTransport

  return createPromiseClient(
      Processor,
      createTransportFunc({
        baseUrl: "https://processors.localize.sh/" + fileExtension,
      })
  )
}

export const useParseRequest = (fileExtension, resource, queryKey = "queryKey") => {
  const $promiseClient = useRef(null);
  const segments = useRef(undefined);

  const {setSegments} = useSegmentsData();

  useEffect(() => {
    if(fileExtension) {
      $promiseClient.current = createMyPromiseClient(fileExtension)
    }

  }, [fileExtension, resource, queryKey])

  const parseRequest = new ParseRequest({resource})

  const {data, ...rest} = useQuery({
    queryFn: () =>
        $promiseClient.current?.parse(parseRequest, {}).then((res) => {
          if(res.document.segments) {
            segments.current = fromProtoSegments(res.document.segments);
            setSegments(SegmentType.source, segments.current)
          }
          return res
        }),
    queryKey: ["parse", fileExtension, queryKey],
    enabled: !!fileExtension && !!resource
  })


  const {layout} = data?.document ? data?.document : {}

  return {layout, segments: segments.current, ...rest};
}

export const stringifyRequest = async (fileExtension, document) => {
  const $promiseClient = createMyPromiseClient(fileExtension)
  const {segments} = document

  const stringifyRequest = new StringifyRequest({document: {...document, segments: toProtoSegments(segments)}})

  try {
    return $promiseClient?.stringify(stringifyRequest, {}).then(resp => resp.resource)
  } catch (err) {
    return err
  }
}