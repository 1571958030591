import React from "react";
import cs from "classnames";

import cl from "./Button.module.css";


export const ButtonThemes = {
    primary: "primary",
    secondaryBlack: "secondaryBlack",
    secondaryRed: "secondaryRed"
}

const Button = ({
                                           label,
                                           handleClick,
                                           colorTheme = ButtonThemes.primary,
                                           isDisabled= false,
                                           icon,
                                           isFullWidth = false
                                       }) => {
    const classes = cs(cl.container, cl[colorTheme], isDisabled && cl.disabled, isFullWidth && cl.fullWidth);

    return (
        <div className={classes} onClick={() => !isDisabled && handleClick()}>
            {icon && icon}
            {label}
        </div>
    );
};

export default Button;