import React, {} from 'react';
import './Preloader.css'


const Preloader = ({
                       customWrapperClass = "",
                       size = 16,
                       color = undefined,
                       borderWidth = 2,
                       top
                   }) => {

    const spinnerIconStyles = {
        width: `${size}px`,
        height: `${size}px`,
        border: `solid ${borderWidth}px transparent`,
        borderTopColor: color,
        borderLeftColor: color,
    }

    return (
        <span
            className={customWrapperClass + ` uik-spinner_container`}
            style={{height: size + borderWidth * 2, marginTop: top && `${top}px`}}>
            <span id="uik-loading-bar-spinner" className='uik-spinner'>
                <div className="uik-spinner-icon" style={spinnerIconStyles}> </div>
            </span>
        </span>
    );
};

export default Preloader;
