import React, {createContext, useContext, useRef} from 'react';

export const SegmentType = {
    source: "source",
    target: "target"
}

export const SegmentsContext = createContext({});

export const useSegmentsData = () => {
    return useContext(SegmentsContext);
}

export const SegmentsProvider = ({children}) => {

    const sourceSegments = useRef([]);
    const targetSegments = useRef([]);

    const isSourceSegmentsEdited = useRef(false);

    const setIsSourceSegmentsEdited = (isEdited) => {
        isSourceSegmentsEdited.current = isEdited;
    }
    const getIsSourceSegmentsEdited = () => isSourceSegmentsEdited.current;

    const setSegments = (segmentType, segments) => {
        setIsSourceSegmentsEdited(false);
        if(segments === null) {
            sourceSegments.current = [];
            targetSegments.current = [];
            return;
        }

        if(segmentType === SegmentType.source) {
            sourceSegments.current = segments;
        } else {
            targetSegments.current = segments;
        }

    }

    const getSegments = (segmentType) => {
        if(segmentType === SegmentType.source) {
            return sourceSegments.current;
        } else {
            return targetSegments.current;
        }
    }

    const addSegment = (segmentType, segment) => {
        if(segmentType === SegmentType.source) {
            isSourceSegmentsEdited.current = true;

            const indexIfExist = sourceSegments.current.findIndex((s) => s.id === segment.id)

            if(indexIfExist !== -1) {
                sourceSegments.current.splice(indexIfExist, 1, segment)
            }
        } else {
            const indexIfExist = targetSegments.current.findIndex((s) => s.id === segment.parentId)

            if(indexIfExist !== -1) {
                targetSegments.current.splice(indexIfExist, 1, segment)
            } else {
                targetSegments.current.push(segment)
            }
            console.log(`targetSegments length: ${targetSegments.current.length}`)
        }
    }

    const removeSegment = (segmentType, segmentId) => {
        if(segmentType === SegmentType.source) {
            return
        } else {
            targetSegments.current = targetSegments.current.filter(s => s.id !== segmentId)
            console.log(`targetSegments length: ${targetSegments.current.length}`)
        }
    }

    return <SegmentsContext.Provider value={{
        setSegments,
        getSegments,
        addSegment,
        removeSegment,
        getIsSourceSegmentsEdited,
        setIsSourceSegmentsEdited
    }}>
        {children}
    </SegmentsContext.Provider>
}