import {useState} from "react";
import {Editor} from '@localizesh/editor';
import FileLoader from "./components/FileLoader";
import CheckBoxSwitch from "./components/CheckBoxSwitch";
import MarkdownView from "./components/MarkdownView";
import { saveAs } from 'save-files/dist/FileSaver';
import {getFileExtension} from "./helpers/string";
import {OnChangeSegmentStatus} from "./helpers/constants";
import {stringifyRequest, useParseRequest} from "./services/processor";
import Preloader from "./components/Shared/Preloader";
import {SegmentType, useSegmentsData} from "./provider/segmentsProvider";
import Button from "./components/Shared/Button";

import './App.css';

function App() {

    const [isDevMode, setIsDevMode] = useState(false)
    const [isAllowSourceEdit, setIsAllowSourceEdit] = useState(false)
    const [currentFileExtension, setCurrentFileExtension] = useState(undefined)

    const [markDown, setMarkDown] = useState(null)

    const {layout, segments, isFetching: isParseFetching} = useParseRequest(currentFileExtension, markDown?.source, markDown?.fileName)
    const [isResourceFetching, setIsResourceFetching] = useState(false)

    const {getSegments, addSegment, removeSegment, getIsSourceSegmentsEdited, setIsSourceSegmentsEdited} = useSegmentsData()

    const onFileLoad = (file) => {
        const {name: fileName} = file.file
        const fileExtension = getFileExtension(fileName)
        console.log(`Downloaded file with ${fileExtension} extension`)

        setCurrentFileExtension(fileExtension === "yml" ? "yaml" : fileExtension)
        setMarkDown({source: file.fileText, target: null, fileName})
    }

    const handleStringify = async () => {
        if(getIsSourceSegmentsEdited()) {
            await setResource(SegmentType.source)
            setIsSourceSegmentsEdited(false)
        }
        await setResource(SegmentType.target)
    }

    const onSegmentChange = async (
        changedSegment,
        editableSegmentType,
        onChangeSegmentStatus,
        callback
    ) => {
        const sourceSegments = getSegments(SegmentType.source);
        if (!sourceSegments) return;

        setTimeout(() => {
            if (onChangeSegmentStatus === OnChangeSegmentStatus.resetTranslate) {
                if (sourceSegments) {
                    const sourceSegmentId = changedSegment.parentId || changedSegment.id;
                    const sourceSegment = sourceSegments.find((segment) => segment.id === sourceSegmentId)
                    if(sourceSegment) {
                        removeSegment(editableSegmentType, sourceSegmentId);
                        callback("success", sourceSegment);
                    }
                }
            } else if (onChangeSegmentStatus === OnChangeSegmentStatus.submit) {
                const newSegment = {
                    ...changedSegment,
                    id: changedSegment.parentId || changedSegment.id,
                };
                addSegment(editableSegmentType, newSegment)
                callback("success", {...changedSegment, id: newSegment.id});

            }
        }, 400)
    }

    const setResource = (segmentType = "target") => {
        const sourceSegments = getSegments(SegmentType.source);
        const targetSegments = getSegments(SegmentType.target);

        let segmentsForStructure = sourceSegments

        if(segmentType === SegmentType.target) {
            segmentsForStructure = sourceSegments.map((s) => {
                const segmentIndex = targetSegments.findIndex((el) => el.id === s.id)
                if(segmentIndex !== -1) {
                    return targetSegments[segmentIndex]
                } else {
                    return s
                }
            })
        }

        setIsResourceFetching(true)
        stringifyRequest(currentFileExtension, {segments: segmentsForStructure, layout}).then((resource) => {
            setMarkDown((state) => {
                return {...state, [segmentType]: resource}
            })
        }).finally(() => setIsResourceFetching(false))
    }

    const handleSaveAs = (fileName) => {
        const file = new File([markDown.target], fileName, {type: "text/plain;charset=utf-8"});
        saveAs(file);
    }

    return (
    <div className="App">
        <header className='header'>
            <FileLoader onFileLoad={onFileLoad}
                        isSaveActive={!!markDown?.target}
                        handleSaveAs={handleSaveAs}
            />
            <div className='control'>
                {(layout && segments && isDevMode) &&
                <Button label={
                    <div className="stringify-btn-label">
                        <span style={{marginRight: isResourceFetching && 16}}>Stringify</span>
                        {isResourceFetching && <Preloader color="white"/>}
                    </div>
                }
                        handleClick={handleStringify}
                />}
                <div className='switch'>
                    <span className='switch__title'>Allow edit source</span>
                    <CheckBoxSwitch isChecked={isAllowSourceEdit}
                                    onHandleChange={() => setIsAllowSourceEdit(!isAllowSourceEdit)}
                    />

                </div>
                <div className='switch'>
                    <span className='switch__title'>Dev Mode</span>
                    <CheckBoxSwitch isChecked={isDevMode}
                                    onHandleChange={() => setIsDevMode(!isDevMode)}
                    />
                </div>
            </div>
        </header>
        <div className='container'>
            {(layout && segments) ? <Editor segments={{target: getSegments(SegmentType.target), source: getSegments(SegmentType.source)}}
                                            layout={JSON.parse(layout)}
                                            isAllowSourceEdit={isAllowSourceEdit}
                                            onSegmentChange={onSegmentChange}
            /> : isParseFetching ?
                <Preloader
                    top={120}
                    size={80}
                    borderWidth={3}
                /> : null}
        </div>

        {isDevMode && (isResourceFetching ? <Preloader size={70} top={32} /> : <MarkdownView mdData={markDown} />)}
        <footer className='footer' />
    </div>
  );
}

export default App;
