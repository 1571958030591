import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SegmentsProvider} from "./provider/segmentsProvider";

export const queryClientDefaultOptions = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            isFetchedAfterMount: true,
        }
    }
}
const queryClient = new QueryClient(queryClientDefaultOptions);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <SegmentsProvider>
                <App/>
            </SegmentsProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
